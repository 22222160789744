import { Permission } from '../enums';
import { IUser, Organisation } from '../models';

export interface Actor {
  id: string;
  token: string;
  partnerId: string;
  permissions: Permission[];
  profile: IUser;
  currentOrg: Organisation;
  intercom: string | null;
  isWarehouseContact: boolean;
}
