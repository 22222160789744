import { DataCollectionTemplateId, TaskStatus, WarehouseUploadType } from '@core/enums';
import { Badge } from '@shared/badge';

export interface EmissionDashboardTotals {
  total: number;
  transport: number;
  warehouse: number;
}

export interface EmissionDashboardMethods {
  air: number;
  sea: number;
  surface: number;
}

export interface ReportedWarehouses {
  reported: number;
  partiallyReported: number;
  notReported: number;
}

export interface EmissionGuestRecord {
  warehouseId: string;
  warehouseName: string;
  totals: EmissionDashboardTotals;
}

export interface EmissionGuestRecordUI {
  warehouseName: string;
  totalCo2: number;
  transportCo2: number;
  warehouseCo2: number;
}

export interface EmissionsGuestResponse {
  totals: EmissionDashboardTotals;
  records: EmissionGuestRecord[];
}

export interface EmissionsGuest {
  totals: EmissionDashboardTotals;
  list: EmissionGuestRecordUI[];
}

export interface TasksTotals {
  assigned: number;
  notReported: number;
  failures: number;
}

export interface TaskRecord {
  warehouseId: string;
  warehouseName: string;
  reportingMonth: Date;
  template: string;
  templateId?: DataCollectionTemplateId;
  type: WarehouseUploadType;
  status: TaskStatus;
  reportingContacts: string[];
  filename: string | null;
  error: string[] | null;
  uploadedOn: Date | null;
  statusBadge: Badge<TaskStatus>;
}

export interface TasksResponse {
  totals: TasksTotals;
  records: TaskRecord[];
}
