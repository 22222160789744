import { Exclude, Expose } from 'class-transformer';

import { measurementSystemList } from '@core/constants';
import { MeasurementSystem } from '@core/enums';
import { IDataCollectionUserWarehouse, SelectOption } from '@core/models';
import { getDataCollectionTemplateName } from '@core/utils';
import { Template } from '@core/models/template';

@Exclude()
export class DataCollectionUserWarehouse implements IDataCollectionUserWarehouse {
  @Expose()
    canUseTransportTemplate!: boolean;

  @Expose()
    canUseWarehouseTemplate!: boolean;

  @Expose()
    canUseFinanceTemplate!: boolean;

  @Expose()
    id!: string;

  @Expose()
    measurementSystem!: MeasurementSystem;

  @Expose()
    name!: string;

  @Expose()
    transportEmissionsTemplateId!: string;

  @Expose()
    warehouseEmissionsTemplateId!: string;

  @Expose()
    financeTemplateId!: string;

  getTemplateList(templates: Template[]): SelectOption<string>[] {
    const templateList: SelectOption<string>[] = [];

    if (this.canUseWarehouseTemplate) {
      const templateId = this.warehouseEmissionsTemplateId;

      if (templateId) {
        templateList.push({ key: templateId, value: getDataCollectionTemplateName(templates, templateId) });
      }
    }

    if (this.canUseTransportTemplate) {
      const templateId = this.transportEmissionsTemplateId;

      if (templateId) {
        templateList.push({ key: templateId, value: getDataCollectionTemplateName(templates, templateId) });
      }
    }

    if (this.canUseFinanceTemplate) {
      const templateId = this.financeTemplateId;

      if (templateId) {
        templateList.push({ key: templateId, value: getDataCollectionTemplateName(templates, templateId) });
      }
    }

    return templateList.sort((a, b) => a.value.localeCompare(b.value));
  }

  get selectedMeasurementSystem(): SelectOption<MeasurementSystem> {
    const measurementSystemOption = measurementSystemList.find(({ key }) => key === this.measurementSystem);

    return measurementSystemOption ?? ({} as SelectOption<MeasurementSystem>);
  }
}
