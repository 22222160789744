import { Transport1DialogComponent } from '@feature/templates/transport1';
import { Transport4DialogComponent } from '@feature/templates/transport4';
import { Warehouse1DialogComponent } from '@feature/templates/warehouse1';
import { Warehouse2DialogComponent } from '@feature/templates/warehouse2';

import {
  DataCollectionTemplateCategory,
  DataCollectionTemplateFormat,
  DataCollectionTemplateId,
  MeasurementSystem,
  UOMSymbol,
  UploadStatus,
  WarehouseUploadStatus,
  WarehouseUploadType,
} from '../enums';
import { ExtraParams, RequestParams } from '../models';
import { TemplateType } from './template';

export interface IDataCollection {
  id: string;
  reportingDate: string;
  filename: string | null;
  userName: string;
  status: WarehouseUploadStatus;
  type: WarehouseUploadType;
  error: string[] | null;
  createdAt: string;
  templateId: string | null;
  originalStatus: WarehouseUploadStatus;
  warehouseId?: string | null;
  canDownload?: boolean;
  warehouseName?: string;
}

export interface IDataCollectionUserWarehouse {
  id: string;
  name: string;
  measurementSystem: MeasurementSystem;
  canUseWarehouseTemplate: boolean;
  canUseTransportTemplate: boolean;
  canUseFinanceTemplate: boolean;
  transportEmissionsTemplateId: string;
  warehouseEmissionsTemplateId: string;
  financeTemplateId: string;
}

export interface DataCollectionNotReportedMonthList {
  [year: string]: number[];
}

export interface UploadWarehouseDataCollectionHistoryFilePayload {
  warehouseId: string;
  formData: FormData;
}

export interface UploadWarehouseDataCollectionHistoryEntriesPayload {
  warehouseId: string;
  reportingDate: string;
  templateId: string;
  inputData: ManualEntryTemplates;
  type: TemplateType;
}

export interface DataCollectionNotReportedMonthListParams {
  templateId: string;
  warehouseId: string;
}

export interface UploadWarehouseDataCollectionHistoryResponse {
  filename?: string;
  statusCode?: number;
  message?: string[];
}

export interface DataCollectionTemplate {
  id: DataCollectionTemplateId;
  name: string;
  type: WarehouseUploadType;
  category: DataCollectionTemplateCategory;
  format: DataCollectionTemplateFormat;
  shortDescription: string;
  description: string;
}

export interface Transport1Fields {
  ['Total distance road ambient']: number | null;
  ['UOM Total distance road ambient']: UOMSymbol | null;
  ['Total weight road ambient']: number | null;
  ['UOM total weight road ambient']: UOMSymbol | null;
  ['Total emission road ambient']: number | null;
  ['Total distance road cold chain']: number | null;
  ['UOM Total distance road cold chain']: UOMSymbol | null;
  ['Total weight road cold chain']: number | null;
  ['UOM total weight road cold chain']: UOMSymbol | null;
  ['Total emission road cold chain']: number | null;
  ['Total distance air']: number | null;
  ['UOM Total distance air']: UOMSymbol | null;
  ['Total weight air']: number | null;
  ['UOM total weight air']: UOMSymbol | null;
  ['Total emission air']: number | null;
  ['Total distance sea ambient']: number | null;
  ['UOM distance sea ambient']: UOMSymbol | null;
  ['Total weight sea ambient']: number | null;
  ['UOM total weight sea ambient']: UOMSymbol | null;
  ['Total emission sea ambient']: number | null;
  ['Total distance sea cold chain']: number | null;
  ['UOM Total distance sea cold chain']: UOMSymbol | null;
  ['Total weight sea cold chain']: number | null;
  ['UOM total weight sea cold chain']: UOMSymbol | null;
  ['Total emission sea cold chain']: number | null;
}

export interface Transport4Fields {
  ['Total distance road ambient']: number | null;
  ['UOM Total distance road ambient']: UOMSymbol | null;
  ['Total weight road ambient']: number | null;
  ['UOM total weight road ambient']: UOMSymbol | null;
  ['Total distance road cold chain']: number | null;
  ['UOM Total distance road cold chain']: UOMSymbol | null;
  ['Total weight road cold chain']: number | null;
  ['UOM total weight road cold chain']: UOMSymbol | null;
  ['Total distance air']: number | null;
  ['UOM Total distance air']: UOMSymbol | null;
  ['Total weight air']: number | null;
  ['UOM total weight air']: UOMSymbol | null;
  ['Total distance sea ambient']: number | null;
  ['UOM distance sea ambient']: UOMSymbol | null;
  ['Total weight sea ambient']: number | null;
  ['UOM total weight sea ambient']: UOMSymbol | null;
  ['Total distance sea cold chain']: number | null;
  ['UOM Total distance sea cold chain']: UOMSymbol | null;
  ['Total weight sea cold chain']: number | null;
  ['UOM total weight sea cold chain']: UOMSymbol | null;
}

export interface Warehouse1Fields {
  ['Cargo Weight ambient']: number | null;
  ['UOM Cargo Weight ambient']: UOMSymbol | null;
  ['Cargo Weight cold chain']: number | null;
  ['UOM Cargo Weight cold chain']: UOMSymbol | null;
  ['AbbVie Warehouse emission']: number | null;
}

export interface Warehouse2Fields {
  ['Cargo Weight ambient']: number | null;
  ['UOM Cargo Weight ambient']: UOMSymbol | null;
  ['Cargo Weight cold chain']: number | null;
  ['UOM Cargo Weight cold chain']: UOMSymbol | null;
}

export interface DataCollectionMetricsParams {
  reportingStartDate: string;
  reportingEndDate: string;
}

export type ManualEntryTemplates = Transport1Fields | Transport4Fields | Warehouse1Fields | Warehouse2Fields;
export type TemplateComponents =
  | Transport1DialogComponent
  | Transport4DialogComponent
  | Warehouse1DialogComponent
  | Warehouse2DialogComponent;

export type DataCollectionExtraParams = ExtraParams<UploadStatus>;
export type WarehouseDataCollectionHistoryParams = RequestParams<IDataCollection> & { warehouseId: string };
export type UserDataCollectionHistoryParams = RequestParams<IDataCollection> & {
  extraParams?: DataCollectionExtraParams;
};
