import { Injectable } from '@angular/core';
import { SelectOption } from '@core/models';
import { Template, TemplateCategory, TemplateId } from '@core/models/template';
import { BehaviorSubject, map, Observable, ReplaySubject, shareReplay, switchMap, tap } from 'rxjs';
import { TemplatesApiService } from './templates-api.service';

@Injectable({ providedIn: 'root' })
export class TemplatesService {
  get templates$(): Observable<Template[]> {
    return this.ensureInitialized().pipe(
      switchMap(() => this.#templates$.asObservable()),
      shareReplay(1)
    );
  }

  get transportTemplateList$(): Observable<SelectOption<TemplateId>[]> {
    return this.templates$.pipe(
      map(templates => this.getTemplateList(templates, TemplateCategory.TRANSPORT))
    );
  }

  get warehouseTemplateList$(): Observable<SelectOption<TemplateId>[]> {
    return this.templates$.pipe(
      map(templates => this.getTemplateList(templates, TemplateCategory.WAREHOUSE))
    );
  }

  readonly #templates$ = new BehaviorSubject<Template[]>([]);
  private initialization$ = new ReplaySubject<void>(1);

  constructor(private templatesApiService: TemplatesApiService) {}

  private ensureInitialized(): Observable<void> {
    if (!this.#templates$.value.length) {
      return this.templatesApiService.getTemplates().pipe(
        tap(templates => this.#templates$.next(templates)),
        tap(() => this.initialization$.next()),
        tap(() => this.initialization$.complete()),
        map(() => undefined),
        shareReplay(1)
      );
    }
    return this.initialization$.asObservable();
  }

  getTemplates(): Observable<Template[]> {
    return this.templates$;
  }

  getTemplateById(id: string): Observable<Template | undefined> {
    return this.templates$.pipe(
      map(templates => templates.find(template => template.id === id))
    );
  }

  previewTemplate(id: string): Observable<void> {
    return this.templatesApiService.previewTemplate(id);
  }

  getDataCollectionTemplateName(templateId: string): Observable<string> {
    return this.templates$.pipe(
      map(templates => {
        const template = templates.find(t => t.id === templateId);
        return template?.name || '';
      })
    );
  }

  getUIWarehouseTemplates(templateIdList: string[]): Observable<SelectOption<TemplateId>[]> {
    return this.templates$.pipe(
      map(templates =>
        templateIdList
          .map(templateId => {
            const template = templates.find(t => t.id === templateId);
            return template ? {
              key: template.id,
              value: template.name,
              desc: template.shortDescription,
              selected: false,
              category: template.category,
            } : null;
          })
          .filter(Boolean)
          .sort((a, b) => a!.value.localeCompare(b!.value)) as SelectOption<TemplateId>[]
      )
    );
  }

  private getTemplateList(
    templatesList: Template[],
    templateCategory: TemplateCategory
  ): SelectOption<TemplateId>[] {
    return templatesList
      .map(({ category, id, shortDescription, name }) =>
        category === templateCategory
          ? { key: id as unknown as TemplateId, value: name, desc: shortDescription }
          : null
      )
      .filter((item): item is { key: TemplateId; value: string; desc: string } => item !== null)
      .sort((a, b) => a.value.localeCompare(b.value));
  }
}
